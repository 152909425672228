@import "./node_modules/carbon-components/scss/globals/scss/styles.scss";

.bx--modal-close {
  visibility: hidden;
}

.bx--col {
  padding-right: 0;
  padding-left: 0;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}